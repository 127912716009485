.about_container_desktop{
    display: flex;
    align-items: center;
    min-height: 35rem;
    padding-top: 4.37rem;
    height: calc(100vh);
    max-height: 50rem;
    width: 100%;
    .container_description{
        height: 100%;
        width: 50%;
        padding-left: 4em;
        padding-top: 7rem;
        color: white;
        font-family: 'Open Sans Hebrew';
        font-style: italic;
        font-weight: 400;
        div{
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 65%;
            h2{
                margin: 0;
                color: $primaryColor;
                font-family: 'Watone';
                font-style: italic;
                font-size: 4.3em;
                font-weight: 100;
            }
        }
    }
    .container_images{
        display: flex;
        align-items: space-between;
        justify-content: space-around;
        padding: 4em 4em 2em 4em;    
        width: 50%;
        height: 100%;
        max-height: 40rem;
        img{
            max-height: 27.5rem;
            max-width: 50%;
            margin: 2rem 2rem 2rem 0rem;
            border-radius: 5px;
        }
        img:nth-child(1){
            align-self: flex-start;
        }
        img:nth-child(2){
            align-self: flex-end;
            margin-top: 2rem;
        }
    }
}
.about_container_mobile{
    display: none;
}

@media (max-width: 720px){
    .about_container_desktop{
        display: none;
    }
    .about_container_mobile{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        min-height: 100vh;
        padding: 1rem;
        padding-top: 5.37rem;
        h2{
            margin: 0;
            color: $primaryColor;
            font-family: 'Watone';
            font-style: italic;
            font-size: 3.5em;
            font-weight: 100;
        }
        .container_images{
            position: relative;
            display: flex;
            justify-content: space-around;
            top: -1rem;
            height: 23rem;
            img{
                height: 20rem;
                border-radius: 5px;
            }
            img:nth-child(1){
                align-self: flex-end;
            }
            img:nth-child(2){
                align-self: flex-start;
            }
        }  
        p{
            color: white;
            font-family: 'Open Sans Hebrew';
            font-style: italic;
            font-size: 1.2rem;
            font-weight: 400;
        }
    }
}
@media (max-width: 330px){
    .about_container_mobile{
        p{
            font-size: 1.4rem;
        }
    }
}