.timeTable_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 4.37rem;
    overflow: hidden;
    h2{
        margin: 2.5rem 0 0 0;
        color: $primaryColor;
        font-family: 'Watone';
        font-style: italic;
        font-size: 4.3em;
        font-weight: 100;
    }
    .container_table_references_mobile{
        display: none;
    }
    .container_table_references_desktop{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        min-height: 90%;
        // border: 1px solid yellow;
        .container1{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 20%;
            min-height: 100%;
            padding-top: 5rem;
            .callToAction{
                display: flex;
                align-self: flex-start;
                justify-content: center;
                align-items: center;
                background-color: $primaryColor;
                border: none;
                width: 12rem;
                height: 3.2rem;
                border-radius: 5px;
                font-family: 'Watone';
                font-style: italic;
                font-size: 1.7rem;
                color: black;
                margin-top: 4em;
                margin-left: 2.5rem;
                text-decoration: none;
            }
            .callToAction:hover{
                scale: 0.97;
                background-color: $primaryColorHover;
            }
        }
        .container_references{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;  
            width: 20%;
            min-height: 100%;
            padding-top: 5rem;
            margin-bottom: 2rem ;
            h3{
                color: white;
                height: 2.3rem;
                width: 9rem;
                font-family: 'Watone';
                font-style: italic;
                font-size: 1.4rem;
                font-weight: 100;
                margin: 0;
            }
            .reference{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                margin-top: 1.5rem;
                span{
                    color: white;
                    margin-left: 1.5rem;
                    font-family: 'Open Sans Hebrew';
                    font-style: italic;
                    font-size: 1.1rem;
                    font-weight: 400;
                } 
                .image_container{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 4rem;
                    height: 4rem;
                    img{
                        max-width: 3rem;
                        max-height: 3rem;
                    }
                }
            }
        }
        }
}
.container_filters{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    min-height: 10rem;
    padding-left: 2.5rem;
    h3{
        color: white;
        font-family: 'Watone';
        font-style: italic;
        font-size: 1.4rem;
        font-weight: 100;
        margin: 0;
    }
    .button_selection{
        display: flex;
        align-items: center;
        width: 100%;
        margin: 1rem 0 1.5rem 0;
        height: 2.5rem;
        background: none;
        border: none;
        span{
            color: white;
            margin-left: 1.5rem;
            font-family: 'Open Sans Hebrew';
            font-style: italic;
            font-size: 1.1rem;
            font-weight: 400;
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        } 
        .image_container{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.5rem;
            height: 2.5rem;
            img{
                max-width: 1.8rem;
                max-height: 1.8rem;
            }
        }
    }
    .dropdown_menu_training{
        display: flex;
        flex-direction: column;
        position: absolute;
        background-color: black;
        width: 100%;
        width: 18rem;
        top: 5.5rem;
        left: 2rem;
        z-index: 2;
        box-shadow: 0 0.5rem 30px rgba(36, 36, 36, 0.542);
        border-radius: 0 0 0.5rem 0.5rem;
        padding: 0.25rem;

        button{
            margin: 0.5rem 0 0.5rem 0;
        }
        button:hover{
            background-color: rgba(255, 255, 255, 0.171);
        }
    }
    .dropdown_menu_hour{
        display: flex;
        flex-direction: column;
        position: absolute;
        background-color: black;
        width: 100%;
        width: 18rem;
        top: 12.5rem;
        left: 2rem;
        z-index: 2;
        box-shadow: 0 0.5rem 30px rgba(36, 36, 36, 0.542);
        border-radius: 0 0 0.5rem 0.5rem;
        padding: 0.25rem;
        max-height: 20rem;
        overflow-y: scroll;

        button{
            margin: 0.5rem 0 0.5rem 0;
        }
        button:hover{
            background-color: rgba(255, 255, 255, 0.171);
        }
    }
    .dropdown_menu_notVisible{
        display: none;
    }
    .button_selection:hover{
        scale: 0.98;
    }
}
.table{
    background-image: url('../../assets/images/logo_titan.svg');
    background-repeat: no-repeat;
    background-size: 38%;
    background-position: center;
    background-position-y: 55%;
    margin: 0rem 0 2.3rem 0;
    scale: 0.8;
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .days{
        display: flex;
        justify-content: flex-end;
        span:nth-child(1){
            border-left: 0;
        }
    }
    .row{
        display: flex;
        justify-content: flex-end;
        span{
            height: 4.6rem;
            padding: 0;
            img{
                max-width: 50%;
                max-height: 90%;
            }
        }
        span:nth-child(1){
            border-left: 0;
        }
    }
    span{
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        height: 2.3rem;
        width: 9rem;
        font-family: 'Watone';
        font-style: italic;
        font-size: 1.6rem;
        padding: 0 2.5em 0 2.5em;
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-right: 0;
        border-top: 0;
    }
    .separator{
        height: 0.12rem;
        width: 54em;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    } 
    .foot_description{
        color: white;
        font-family: 'Open Sans Hebrew';
        font-size: 1.2rem;
        font-weight: 100;
        width: 100%;
        border: none;
    }
}

@media (max-width: 720px){
    .timeTable_container{
        padding-top: 2.5rem;
        h2{
            font-size: 3.5rem;
        }
        
    }
    .container_table_references_desktop{
        display: none !important;
    }
    .container_table_references_mobile{
        display: flex !important;
        flex-direction: column;
        justify-content:space-around !important;
        align-items: center;
        width: 100%;
        // border: 1px solid green;
        padding-top: 3rem;
        min-height: 80vh;
        .container_filters_mobile{
            display: flex;
            justify-content: center;
        //  border: 1px solid yellow;
            width: 100%;
            height: 5rem;
        }
        .container_references{
            display: flex;
            justify-content: space-around;
            align-items: center;  
            width: 95% ;
            margin-bottom: 2rem ;
            .reference{
                display: flex;
                justify-content: center;
                align-items: center;
                span{
                    color: white;
                    margin-left: 1rem;
                    font-family: 'Open Sans Hebrew';
                    font-style: italic;
                    font-size: 1.1rem;
                    font-weight: 400;
                } 
                img{
                    margin: 0;
                    max-width: 2.5rem;
                    max-height: 2.5rem;
                }
            }
        }
        .callToAction{
            display: flex;
            align-self: center;
            justify-content: center;
            align-items: center;
            background-color: $primaryColor;
            border: none;
            width: 12rem;
            height: 3.2rem;
            border-radius: 5px;
            font-family: 'Watone';
            font-style: italic;
            font-size: 1.7rem;
            color: black;
            text-decoration: none;
        }
    }
    .table{
        span{
            width: 6.6rem;
            font-size: 1.3rem;
        }
        .row{
            span{
                height: 3.5rem;
            }
        }
        .separator{
            width: 39.6rem;
        }
        .foot_description{
            font-size: 1rem;
        }
    }
    .container_filters{
        flex-direction: row;
        justify-content: center;
        padding: 0 !important;
        min-height: auto;
        div{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
        }
        .dropdown_menu_training{
            left: 0;
            border-radius: 0.5rem;
            button{
                div{
                    align-items: flex-start;
                    margin-right: 0.5rem;
                }
            }       
        }
        h3{
            display: flex;
            justify-content: center;
            padding-left: 0;
        }
        button{
            justify-content: center;
            // border: 1px solid red !important;
            
        }
        .button_selection{
            background-color: rgb(72, 72, 72);
            width: 95%;
            border-radius: 0.5rem;
        }
        .dropdown_menu_hour{
            left: auto;
            right: 0.5rem;
            padding: 1rem;
            top: 6rem;
            border-radius: 0.5rem;
        }
    }
}