.services_container{
    min-height: 35rem;
    padding-top: 4.37rem;
    height: 100vh;
    max-height: 50rem;
    width: 100%; 
    h2{
        margin: 2.5rem 0 0 0;
        color: $primaryColor;
        font-family: 'Watone';
        font-style: italic;
        font-size: 4.3em;
        font-weight: 100;
    }
    .services_container_desktop{
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: center;
    }
    .container_cards{
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        width: 100%;
        .container_card_inner{
            width: 100%;
            height: 100%;
            font-family: 'Open Sans Hebrew';
            font-style: italic;
            font-size: 1.4rem;
            font-weight: bold;
            border-radius: 0.2em;
            transition: transform 0.8s;
            transform-style: preserve-3d;
            div{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
            }
            .front-end, .back-end{
                box-shadow: 0 0px 14px 0 rgba(255, 255, 255, 0.09);
                position: relative;
                backface-visibility: hidden;
            }
            .front-end{
                border-radius: 5px;
                .img_touch{
                    display: none;
                }
                .img_pointer{
                    position: absolute;
                    bottom: 0.5rem;
                    right: 1rem;
                    max-width: 5rem;
                }
            }
            .back-end{
                top: -23.75rem;
                transform: rotateY(180deg);
                border-radius: 5px;
                background-image: url('../../assets/images/background_card.webp');
                background-size: contain;
                height: 100%;
                .container_info{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                    width: 100%;
                    border-radius: 5px;
                    padding: 1.5rem 0.5rem 1.5rem 0; 
                    background-color: rgba(0, 0, 0, 0.5);
                    .container_title{
                        align-items: center;
                        height: 15%;
                        h3{
                            font-family: 'Watone';
                            color: $primaryColor;
                            font-size: 1.8rem;
                            font-weight: 100;
                            margin: 0;
                        }
                    }
                    .container_description{
                        height: 70%;
                        li{
                            margin-bottom: 0.5rem;
                            font-family: 'Open Sans Hebrew';
                            font-size: 0.95rem;
                            font-weight: 100;
                        }
                    }
                    .container_button{
                        height: 15%;
                        a{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: $primaryColor;
                            color: black;
                            width: 9rem;
                            height: 2rem;
                            font-family: 'Watone';
                            font-weight: 100;
                            font-size: 1.2rem;
                            border-radius: 0.2rem;
                            text-decoration: none;
                        }
                        a:hover{
                            background-color: $primaryColorHover;
                            scale: 0.98;
                        }
                    }
                }

            }
        }
        .container_card_vertical{
            width: 15.45rem;
            height: 23.75rem;
            perspective: 1000px;
        }
        .container_card_horizontal{
            height: 15.45rem;
            width: 23.75rem;
            perspective: 1000px;
            .back-end{
                top: -15.45rem;
                transform: rotateX(180deg);
                .container_info{
                    padding: 0.8rem 0.6rem 1rem 0.6rem;
                }
            }
        }
        #personalizado_card_desktop, #personalizado_card_mobile{
            .front-end{
                background-image: url('../../assets/images/service1.webp');
                background-size: cover;
                background-position: center;
                width: 100%;
                height: 100%;
            }
            .mobile{
                display: none;
            }
        }
        #total_body_card_desktop, #total_body_card_mobile{
            .front-end{
                background-image: url('../../assets/images/service2.webp');
                background-size: contain;
                background-position: center;
            }
        }
        #boxeo_card_desktop, #boxeo_card_mobile{
            .front-end{
                background-image: url('../../assets/images/service3.webp');
                background-size: cover;
                background-position: center;
                width: 23.25rem;
                height: 15.75rem;
            }
        }
    }
    .container_card_animation_X{
        animation: rotateAnimation_X linear 0.8s;
    }
    .container_card_animation_Y{
        animation: rotateAnimation_Y linear 0.8s;
    }
    .container_card_vertical:hover .container_card_inner{
        transform: rotateY(180deg);
    }
    .container_card_horizontal:hover .container_card_inner{
        transform: rotateX(180deg);
    }
    .services_container_mobile{
        display: none;
    }

}


@media (max-width: 720px){
    .services_container{
        min-height: 50rem;
        max-height: initial;
        .services_container_desktop{
            display: none;
        }
        .services_container_mobile{
            display: flex;
            height: 100%;
            width: 100%;
            .container_info{
                .container_title{
                    min-height: 10% !important;
                    margin-top: 1rem;
                    h3{
                        font-family: 'Watone';
                        color: $primaryColor;
                        font-size: 2.2rem !important;
                        font-weight: 400;
                    }
                }
                .container_description{
                    height: 70%;
                    ul{
                        padding: 0 1rem 0 2rem;
                    }
                    li{
                        margin-bottom: 0.5rem;
                        font-family: 'Open Sans Hebrew';
                        font-size: 1rem !important;
                        font-weight: 600 !important;
                    }
                }
                .container_button{
                    height: 15%;
                    a{
                        background-color: $primaryColor;
                        border: none;
                        width: 9rem;
                        height: 2rem;
                        font-family: 'Watone';
                        font-weight: 100;
                        font-size: 1.2rem;
                        text-decoration: none;
                    }
                }
            }
            .container_cards{
                display: flex;
                align-items: flex-start;
                width: 100%;
                height: 100%;
                padding: 4rem 0rem 4rem 0rem;
                .container_card_inner{
                    position: absolute;
                    z-index: 1;
                    .front-end{
                        .img_pointer{
                            display: none;
                        }
                        .img_touch{
                            display: inline;
                            position: absolute;
                            bottom: 1.3rem;
                            right: 1rem;
                            max-width: 5rem;
                        }
                    }
                }
                .column1{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    width: 50%;
                    height: 100%;
                    // border: 1px solid green;
                }
                .column2{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 50%;
                    margin: 0;
                    // border: 1px solid yellow;
                    .container_title{
                        display: flex;
                        align-items: center;
                        height: 11.62rem;
                        margin-top: 1rem;
                    }
                    h2{
                        display: block;
                        font-size: 3rem;
                        color: $primaryColor;
                        margin: 0;
                    }
                }
                .container_card_horizontal{
                    scale: 0.9;
                    width: 15.45rem;
                    height: 23.75rem;
                    .back-end{
                        position: relative;
                        top: -23.75rem;
                        transform: rotateY(180deg);
                        .container_info{
                            padding: 0;
                        }
                    }
                }
                #personalizado_card_mobile{
                    .front-end{
                        background-size: 30rem 23.75rem ;
                        background-position: -10rem center;
                    }
                    .container_title{
                        height: auto !important;
                    }
                    .container_description{
                        height: auto !important;
                        ul{
                            margin: 0;
                        }
                    }
                    .mobile{
                        display: flex;
                    }
                    .desktop{
                        display: none;
                    }
                }
                #boxeo_card_mobile{
                    .front-end{
                        background-size: cover;
                        background-position: center;
                        height: 23.25rem;
                        width: 15.75rem;
                    }
                }
                #total_body_card_mobile{
                    .front-end{
                        background-size: contain;
                        background-position: center;           
                    }
                }
                .container_card_inner_open{
                    transform: rotateY(180deg) !important;
                    scale: 1.05;
                }
                .container_card_vertical{
                    margin-top: 1rem;
                    scale: 0.90;
                }
            }
            .container_card_vertical:hover .container_card_inner{
                transform: none;
            }
            .container_card_horizontal:hover .container_card_inner{
                transform: none;
            }
        }
        .container_card_animation_Y{
            animation: rotateAnimation_Y linear 0.8s;
        }
        .container_card_animation_X{
            animation: rotateAnimation_Y linear 0.8s;
        }
    }
}
@media (max-width: 330px) {
    .services_container_mobile{
        .container_cards{
            .container_card_inner{
                .back-end{
                    .container_description{
                        li{
                            font-size: 1rem !important;
                            // color: red;
                        }
                    }
                }
            }
        }
        // .container_card_vertical:hover .container_card_inner{
        //     scale: 1.5 !important;
        // }
        // .container_card_horizontal:hover .container_card_inner{
        //     scale: 1.5 !important;
        // }
    }
}

@keyframes rotateAnimation_X {
    0%{
        transform: rotateX(0deg);
    }
    60%{
        transform: rotateX(150deg);
    }
    100%{
        transform: rotateX(0deg);
    }
}
@keyframes rotateAnimation_Y {
    0%{
        transform: rotateY(0deg);
    }
    60%{
        transform: rotateY(150deg);
    }
    100%{
        transform: rotateY(0deg);
    }
}