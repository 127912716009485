
// UI Settings
@import './base/settings';
@font-face {
    font-family: 'Watone';
    src: url('../assets/fonts/Watone.ttf');
    font-style: normal;
    font-weight: normal;
}
@font-face {
   font-family: 'Open Sans Hebrew';
   src: url('../assets/fonts/OpenSansHebrew/OpenSans-Italic-VariableFont_wdth,wght.ttf');
   font-style: italic;
}
@font-face {
    font-family: 'Open Sans Hebrew';
    src: url('../assets/fonts/OpenSansHebrew/OpenSans-VariableFont_wdth,wght.ttf');
    font-style: normal;
 }
// Components
@import './base/app';
@import './components/navbar';
@import './components/landing';
@import './components/about';
@import './components/services';
@import './components/timeTable';
@import './components/footer';
@import './components/loader';
@import './components/shop';
@import './components/footerLanding';
@import './components/background';
// Screens