.loader_container{
    position: absolute;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    background-color: black;
    min-width: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 100;
    img{
        position: absolute;
        max-width: 13rem;
        max-height: 19rem;
        padding-top: 3rem;
    }
    .spinner{
        width: 20rem;
        height: 20rem;
        color: white;
    }
      .spinner:after {
        box-sizing: border-box;
        content: " ";
        display: block;
        width: 20rem;
        height: 20rem;
        border-radius: 50%;
        border: 0.5rem solid currentColor;
        border-color: currentColor transparent currentColor transparent;
        animation: lds-dual-ring 1.1s linear infinite;
      }
    .footer{
        position: absolute;
        bottom: 0;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: black; 
        span{
            color: white;
            font-family: 'Open Sans Hebrew';
            font-style: italic;
            font-weight: 400;
            font-size: 1.2rem;
        }
    }
  }
  .loader_off{
    display: none !important;
  }
@keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @media (max-width: 720px) {
    .loader_container{
      min-height: auto;
      height: 100%;
      img{
        position: absolute;
        max-width: 10rem;
        max-height: 12rem;
        padding-top: 3rem;
    }
    .spinner{
        width: 15rem;
        height: 15rem;
    }
      .spinner:after {
        box-sizing: border-box;
        content: " ";
        display: block;
        width: 15rem;
        height: 15rem;
        border-radius: 50%;
        border: 0.5rem solid currentColor;
        border-color: currentColor transparent currentColor transparent;
        animation: lds-dual-ring 1.1s linear infinite;
      }
      .footer{
        span{
            font-size: 0.9rem;
        }
    }
    }
    
  }