.footer_container{
    width: 100%;
    height: 10rem;
    font-family: 'Open Sans Hebrew';
    font-weight: 600;
    font-size: 0.9rem;
    footer{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 85%;
        background-color: $primaryColor;
        div{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 33%;
            padding: 3rem;
        }
        .container_1{
            align-items: flex-start;
        }
        .container_1_mobile{
            display: none;
        }
        .container_2{
            align-items: center;
            img{
                width: 7rem;
            }
        }
        .container_3{
            align-items: flex-end;
            div{
                width: max-content;
                padding: 0;
            }
        }
        a{
            display: flex;
            align-items: center;
            margin-bottom: 0.25rem;
            margin-top: 0.25rem;
            text-decoration: none;
            color: black;
            img{
                margin-right: 0.3em;
                height: 1.5rem;
            }
        }
        a:hover{
            scale: 0.99;
        }
    }
    .copyrigth{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 15%;
        background-color: black;
        color: white;
        font-size: 0.8rem;
        font-weight: 100;
    }
}


@media (max-width: 720px) {
    .footer_container{
        height: auto;
        font-size: 0.7rem;
        // border: 1px solid red;
        footer{
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            height: 6rem;
            background-color: $primaryColor;
            div{
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 33%;
                padding: 1rem;
            }
            .container_1{
                width: auto;
                align-self: flex-start;
            }
            .container_2{
                position: absolute;
                align-self: center;
                img{
                    width: 4rem;
                }
            }
            .container_3{
                align-self: flex-end;
                div{
                    width: max-content;
                    padding: 0;
                }
            }
            a{
                display: flex;
                align-items: center;
                margin-bottom: 0.1rem;
                margin-top: 0.1rem;
                text-decoration: none;
                color: black;
                img{
                    margin-right: 0.3em;
                    height: 1rem;
                }
            }
            a:hover{
                scale: 0.99;
            }
        }
        .copyrigth{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            background-color: black;
            color: white;
            font-size: 0.8rem;
            font-weight: 100;
            // border: 1px solid red;
        }
    }
}

    
