*{
    box-sizing: border-box;
}
html{
    scroll-behavior: smooth;
}
body{
    background-color: black;
    width: 100vw;
    overflow-x: hidden;
}
*::-webkit-scrollbar{
    width: 6px;
  }
*::-webkit-scrollbar-thumb{
background-color: $primaryColor;
// border: 1px solid #9a6210;
border-radius: 0.125em;
}
*::-webkit-scrollbar-thumb:hover{
background-color: #9a6210;
}

@media (min-width: 0px){
    html{
        font-size: 9px;
    }
}
@media (min-width: 300px){
    html{
        font-size: 10px;
    }
}
@media (min-width: 330px){
    html{
        font-size: 11px;
    }
}
@media (min-width: 365px){
    html{
        font-size: 12px;
    }
}
@media (min-width: 430px){
    html{
        font-size: 14px;
    }
}
@media (min-width: 500px){
    html{
        font-size: 15px;
    }
}
@media (min-width: 600px){
    html{
        font-size: 16px;
    }
}
@media (min-width: 700px){
    html{
        font-size: 17px;
    }
}
@media (min-width: 720px){
    html{
        font-size: 12px;
    }
}
@media (min-width: 750px){
    html{
        font-size: 11px;
    }
}
@media (min-width: 850px){
    html{
        font-size: 13px;
    }
}
@media (min-width: 1050px){
    html{
        font-size: 14px;
    }
}
@media (min-width: 1350px){
    html{
        font-size: 15px;
    }
}
@media (min-width: 1450px){
    html{
        font-size: 18px;
    }
}
@media (min-width: 1550px){
    html{
        font-size: 20px;
    }
}
@media (min-width: 1700px){
    html{
        font-size: 22px;
    }
}
@media (min-width: 1900px){
    html{
        font-size: 24px;
    }
}
