.navbar_container{
    position: fixed;
    z-index: 1;
    top: 0px;
    width: 100vw;
    font-size: 1rem;
    height: 4.37em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $blackColor;
    padding: 0 2.56em 0 2.56em;
    .container_logo{
        display: flex;
        justify-content: flex-start;
        width: 25%;
        img{
            height: 2.56rem;
        }
    }
    .container_buttons_mobile{
        display: none;
    }
    .container_buttons{
        display: flex;
        justify-content: space-around;
        width: 50%;
    }
    .button_scroll{
        color: $primaryColor;
        text-decoration: none;
        font-family:'Watone';
        font-style: italic;
        font-size: 1.25em;
        background-color: transparent;
        border: none;
    }
    .button_scroll:hover{
        color: $primaryColorHover;
        scale: 0.98;
        cursor: pointer;
    }
    .container_icons{
        display: flex;
        justify-content: flex-end;
        width: 25%;
        button{
            background-color: transparent;
            border: none;
            cursor: pointer;
        }
        img{
            height: 1.87em;
        }
    }
    .upcoming_poster{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 15rem;
        // height: 10rem;
        padding: 1.5rem;
        left: 62%;
        top: 4rem;
        background-color: $primaryColor;
        border-radius: 0.5rem;
        font-family: 'Open Sans Hebrew';
        font-style: italic;
        font-size: 1.1rem;
        font-weight: 500;
        p{
            display: inline;
            font-family:'Watone';
            font-size: 1.4em;
            font-style: italic;
            font-weight: 500;
        }
    }
    .upcoming_poster_login{
        left: auto;
        right: 2.5rem;
    }
    .upcoming_poster_none{
        display: none;
    }
}

@media (max-width: 720px){
    .navbar_container{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 0 0 0 1rem;
        .container_logo{
            align-self: flex-start;
            width: auto;
        }
        .container_buttons{
            display: none;
        }
        .container_buttons_mobile{
            display: block;
            display: flex;
            align-items: center;
            margin-right: 0.5rem;
            width: 20%;
            justify-content: space-between;
            align-self: flex-end;
            #button_shop_mobile{
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 2.3rem;
                max-height: 2.3rem;
                img{
                    max-width: 2.2rem;
                    max-height: 2.2rem;
                }
            }
            button{
                background-color: transparent;
                border: none;
            }
            img{
                height: 2.4rem;
            }
            .dropdown_menu{
                // border: 1px solid red;
                position: absolute;
                right: 1rem;
                top: 4.37rem;
                padding: 1rem 0rem 1rem 0rem;
                border-radius: 0.5rem 0 0 0.5rem;
                background-color: $primaryColor;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 15rem;
                width: 50%;
                button, a{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 2.5rem;
                    color: black;
                    font-size: 1.6rem;
                    width: 100%;
                }
                button:focus, a:focus{
                    background-color: $primaryColorHover;
                    scale: 0.98;
                }
            }
            .dropdown_menu_none{
                display: none;
            }
        }
        .container_icons{
            display: flex;
            align-items: center;
            justify-content: space-around;
            position: absolute;
            align-self: center;
            img{
                height: 2.5rem;
            }
        }
        .upcoming_poster{
            left: auto;
            right: 1rem;
            top: 4.37rem;
        }
        .upcoming_poster_login{
            width: 50%;
            left: 25%;
        }
    }
}