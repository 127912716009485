.landing_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 35rem;
    height: calc(100vh - 4.37rem);
    max-height: 50rem;
    margin: 0;
    margin-top: 5em;
    background-color: black;
    box-shadow: inset 0px -2.5rem 2.5rem 0.5rem rgba(0, 0, 0, 0.9);
    .container_title_callToAction{
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 80%;
        // border: 1px solid green ;
        .container_title{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            // border: 1px solid red;
        }
        span{
            font-family: 'Open Sans Hebrew';
            font-style: italic;
            font-weight: bold;
            font-size: 4.6em;
            color: $primaryColor;
        }
        span:nth-child(2){
            color: transparent;
            -webkit-text-stroke: $primaryColor 1px ;
        }
        .callToAction{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $primaryColor;
            border: none;
            width: 12rem;
            height: 3.2rem;
            border-radius: 5px;
            font-family: 'Watone';
            font-style: italic;
            font-size: 1.7rem;
            color: black;
            margin-top: 4em;
            text-decoration: none;
        }
        .callToAction:hover{
            scale: 0.97;
            background-color: $primaryColorHover;
        }
    }
    .contacts_icons{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 40%;
        left: 107%;
        width: 2.3rem;
        height: 2.3rem;
        a{
            width: 100%;
            img{
                width: 100%;
            }
        }
    }
    .instagram{
        left: calc(92% + 2.6rem);
    }
    .wpp{
        left: 92%;
    }
}
.landing_container::before{
    z-index: 0;
    min-height: 35rem;
    height: calc(100vh - 4.37rem);
    max-height: 50rem;
    content: "";
    position: absolute;
    top: 4.2rem;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('../../assets/images/background.webp');
    background-repeat: no-repeat;
    background-position: center center ;
    background-size: 85vw;
}

@media (max-width: 720px){
    .landing_container::before{
        min-height: initial;
        height: auto;
        max-height: initial;
        background-image: url('../../assets/images/background_mobile.webp');
        opacity: 100%;
        background-size: auto 68%;
        background-position: left center;
        z-index: 0;
    }
    .landing_container{
        min-height: initial;
        max-height: initial;
        justify-content: flex-end;
        align-items: flex-start;
        padding-right: 2rem;
        width: 100%;
        background-color: black;
        margin-top: 4.4rem;
        box-shadow: 0px 0.5rem 2.5rem 0.5rem rgba(0, 0, 0, 0.9);
        .container_title_callToAction{
            align-items: flex-end;
            justify-content: space-between;
            padding-top: 3rem;
            padding-bottom: 3rem;
            min-height: 35rem;
            height: calc(100% - 4.37rem);
            width: calc(100% - 2rem);
            div{
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
            span{
                display: flex;
                justify-content: flex-end;
                font-size: 2.5rem;
                width: 100%;
            }
            span:nth-child(2){
                width: 50%;
            }
            button{
                align-self: flex-end;
                font-size: 1.8rem;
            }
            .contacts_icons{
                top: 45%;
                left: 90%
            }
        }
    }
}